import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"

const NotFoundPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismic404 || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      <Section data={pageData.data} />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    prismic404(lang: { eq: "en-us" }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          raw
        }
        description {
          text
        }
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        content {
          raw
        }
        button_text {
          text
        }
        button_link {
          uid
          type
          lang
          url
          link_type
        }
      }
    }
  }
`
